/** @jsx jsx */
import { jsx } from '@emotion/react';

import config from '../../../gatsby-config';
import serviceHeaderImg from '../../../static/img/illustration-services-support-training.svg';

const ServicesHeaderSection = (props) => {
    const { t } = props;

    return (
        <header
            id="headerSection"
            key="header"
            css={styles.mainSection}
        >
            <div className="container-fluid pt30 mb20">
                <div className="row text-center">
                    <div className="col-12 mb20">
                        <h1 style={{ color: 'white' }}>{t('services:mainTitle')}</h1>
                        <h3 className="mv20" style={{ color: 'white' }}>{t('services:subTitle')}</h3>
                        <img
                            src={serviceHeaderImg}
                            alt={t('product.header.msg1')}
                            style={{ marginBottom: 30, maxHeight: 250, width: '100%' }}
                        />
                    </div>
                </div>
            </div>
        </header>
    );
};

const styles = {
    mainSection: {
        paddingTop: config.siteMetadata.navHeaderHeight,
        background: 'linear-gradient(#2E5CA6, #2E5CA6)'
    }
};

export default ServicesHeaderSection;
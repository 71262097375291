import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import MainLayout from '../../components/main-layout';
import ServicesHeaderSection from './services-header-section';
import ServicesCallToActionSection from './services-call-to-action-section';
import QuickStartPackage from './quick-start-package';
import CustomCardDevelopment from './custom-card-development';
import CustomConnectorDevelopment from './custom-connector-development';
import DataWarehouseAutomation from './data-warehouse-automation';
import RequestDemoSection from '../../components/RequestDemoSection';

const Services = ( props ) => {
    const { t, i18n } = useTranslation(),
        { pageContext } = props;

    return (
        <MainLayout pageContext={pageContext}>
            <ServicesHeaderSection t={t} i18n={i18n} />
            <ServicesCallToActionSection t={t} i18n={i18n} />
            <QuickStartPackage t={t} i18n={i18n} />
            <CustomCardDevelopment t={t} i18n={i18n} />
            <CustomConnectorDevelopment t={t} i18n={i18n} />
            <DataWarehouseAutomation t={t} i18n={i18n} />
            <RequestDemoSection key="request-demo-section" t={t} i18n={i18n} />
        </MainLayout>
    )
};

export default Services;
import { navigate } from 'gatsby';
import { Button } from '@blueprintjs/core';
/** @jsx jsx */
import { jsx } from '@emotion/react';

const ServicesCallToActionSection = (props) => {
    const { t, i18n } = props;

    return (
        <section id="callToActionSection" key="callToAction">
            <div className="container">
                <div className="row mb30 pt40">
                    <div className="col-12 text-center">
                        <h2>{t('services:callToActionTitle')}</h2>
                        <h4>{t('services:callToActionMsg1')}</h4>
                        <h4>{t('services:callToActionMsg2')}</h4>
                        <Button
                            text={t('services:talkToTheTeam').toUpperCase()}
                            intent="success"
                            style={{ boxShadow: 'none', marginTop: 30, marginBottom: 30 }}
                            large={true}
                            onClick={() => i18n.language === 'en' ? navigate('/en/contact') : navigate('/es/contacto')}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesCallToActionSection;
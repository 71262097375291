/** @jsx jsx */
import { jsx } from '@emotion/react';
import { blue } from 'material-colors';

const CustomConnector = (props) => {
    const { t } = props;

    const styles = {
        icon: {
            fontSize: 24,
            color: blue[800]
        }
    };

    return (
        <section id="customConnectorDevelopmentSection" key="customConnectorDevelopment" style={{ backgroundColor: '#F9FBFD' }}>
            <div className="container">
                <div className="row pt40">
                    <div className="col-12 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-endorsed bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h4>{t('services:customConnectorDevelopment.title')}</h4>
                            </div>
                        </div>
                        <p>{t('services:customConnectorDevelopment.description')}</p>
                    </div>
                </div>
                <div className="row pb20">
                    <div className="col-12 col-xl-6 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-double-chevron-right bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('services:mainObjective')}</h5>
                            </div>
                        </div>
                        <p>{t('services:customConnectorDevelopment.mainObjective')}</p>
                    </div>
                    <div className="col-12 col-xl-6 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-double-chevron-right bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('services:whoIsItFor')}</h5>
                            </div>
                        </div>
                        <p>{t('services:customConnectorDevelopment.whoIsItFor')}</p>
                    </div>
                    <div className="col-12 col-xl-6 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-double-chevron-right bp3-icon-large" style={styles.icon} />
                            </div>
                            <div style={{ paddingRight: 10 }}>
                                <h5>{t('services:whatsIncluded')}</h5>
                            </div>
                        </div>
                        <p>{t('services:customConnectorDevelopment.whatsIncluded')}</p>
                    </div>
                    <div className="col-12 col-xl-6">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-double-chevron-right bp3-icon-large" style={styles.icon} />
                            </div>
                            <div style={{ paddingRight: 10 }}>
                                <h5>{t('services:someExamples')}</h5>
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>{t('services:customConnectorDevelopment.example1')}</li>
                                <li>{t('services:customConnectorDevelopment.example2')}</li>
                                <li>{t('services:customConnectorDevelopment.example3')}</li>
                                <li>{t('services:customConnectorDevelopment.example4')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomConnector;
import { navigate } from 'gatsby';
import { Button } from '@blueprintjs/core';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { grey, blue } from 'material-colors';
import ReactTooltip from 'react-tooltip';
import { Icon  } from "@blueprintjs/core";

const QuickStartPackage = (props) => {
    const { t, i18n } = props;

    const styles = {
        icon: {
            fontSize: 24,
            color: blue[800]
        },
        imgBox: {
            textAlign: 'center',
            backgroundColor: 'white',
            border: `1px solid ${grey[200]}`
        },
        badge: {
            position: 'absolute',
            marginTop: -15,
            marginLeft: 15
        }
    };

    const utmString = '?utm_medium=online_campaign&utm_source=biuwer_website&utm_campaign=2020_biuwer_free_start_package&utm_content=biuwer_services_page&utm_term=iminterested';

    return (
        <section id="quickStartPackageSection" key="quickStartPackage" style={{ backgroundColor: '#F9FBFD' }}>
            <div className="container">
                <div className="row pt40">
                    <div className="col-12 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-endorsed bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h4>{t('services:quickStartPackage.title')}</h4>
                            </div>
                        </div>
                        <p>{t('services:quickStartPackage.description')}</p>
                    </div>
                </div>
                <div className="row pb20">
                    <div className="col-12 col-xl-6 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-double-chevron-right bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('services:mainObjective')}</h5>
                            </div>
                        </div>
                        <p>{t('services:quickStartPackage.mainObjective')}</p>
                    </div>
                    <div className="col-12 col-xl-6 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-double-chevron-right bp3-icon-large" style={styles.icon} />
                            </div>
                            <div>
                                <h5>{t('services:whoIsItFor')}</h5>
                            </div>
                        </div>
                        <p>{t('services:quickStartPackage.whoIsItFor')}</p>
                    </div>
                    <div className="col-12 col-xl-6 pb20">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 10 }}>
                                <span className="bp3-icon-double-chevron-right bp3-icon-large" style={styles.icon} />
                            </div>
                            <div style={{ paddingRight: 10 }}>
                                <h5>{t('services:whatsIncluded')}</h5>
                            </div>
                            <div data-tip data-for={'quick-start-package-whats-included'}>
                                <Icon icon="info-sign" iconSize={20} color={blue[800]} />
                            </div>
                            <ReactTooltip
                                id={'quick-start-package-whats-included'}
                                place="bottom"
                                type="dark"
                                effect="float"
                            >
                                <div style={{ fontSize: 11, margin: 10 }}>
                                    <ul style={{ paddingInlineStart: 0 }}>
                                        <li>{t('services:quickStartPackage.includedDetail1')}</li>
                                        <li>{t('services:quickStartPackage.includedDetail2')}</li>
                                        <li>{t('services:quickStartPackage.includedDetail3')}</li>
                                        <li>{t('services:quickStartPackage.includedDetail4')}</li>
                                        <li>{t('services:quickStartPackage.includedDetail5')}</li>
                                    </ul>
                                </div>
                            </ReactTooltip>
                        </div>
                        <p>{t('services:quickStartPackage.whatsIncluded')}</p>
                    </div>
                    <div className="col-12 col-xl-6">
                        <div style={styles.imgBox}>
                            <div style={styles.badge}>
                                <span className="badge bg-success">{t('services:specialOffer')}</span>
                            </div>
                            <div className="mt20 mh20">
                                <span>{t('services:quickStartPackage.specialOfferDetail')}</span>
                            </div>
                            <Button
                                text={t('services:imInterested').toUpperCase()}
                                intent="success"
                                style={{ boxShadow: 'none', marginTop: 20, marginBottom: 20 }}
                                large={true}
                                onClick={() => i18n.language === 'en' ? navigate('/en/lp/free-quick-start-package' + utmString) : navigate('/es/lp/paquete-inicio-rapido-gratuito' + utmString)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default QuickStartPackage;